$gray: #9c9c9c;
$primary: #00738d;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.containerbanner{
  background-color: rgba(0, 0, 0, 0.424);
  padding: 50px 20px;
}

.titlebanner{
  font-size: 50px;
  color: white;
  font-weight: 700;
}

.item_menu{
  font-size: 24px;
  cursor: pointer;
  color: $gray;
  &:hover{
    color: $primary;
  }
}
.item_menu_icon{
  font-size: 32px;
  cursor: pointer;
  color: $gray;
  &:hover{
    color: $primary;
  }
}

.fondo{
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 700px) { 
  .titlebanner{
    font-size: 20px;
  }
  .containerbanner{
    padding: 10px 5px;
  }

}

.card-img-top{
  height: 120px;
}

.nolink{
  text-decoration: none;
  color: inherit;
}

.iconhome{
  font-size: 80px;
}